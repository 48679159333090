export interface IEventLogFields {
  EVENT_ID: string;
  EVENT_CATEGORY: string;
  EVENT_DATE: string;
  EVENT_DESCRIPTION: string;
  EVENT_OBJ: string;
  EVENT_TYPE: string;
  EVENT_SOURCE: string;
  EVENT_USER: string;
  EVENT_ERROR_CODE: string;
  EVENT_DATE_TO: string;
  EVENT_DATE_FROM: string;
  EVENT_TIME_TO: string;
  EVENT_TIME_FROM: string;
}

export const fields: IEventLogFields = {
  EVENT_ID: "id",
  EVENT_CATEGORY: "category",
  EVENT_DATE: "date",
  EVENT_DESCRIPTION: "description",
  EVENT_OBJ: "obj",
  EVENT_TYPE: "type",
  EVENT_SOURCE: "source",
  EVENT_USER: "user",
  EVENT_ERROR_CODE: "errorCode",
  EVENT_DATE_TO: "dateTo",
  EVENT_DATE_FROM: "dateFrom",
  EVENT_TIME_TO: "timeTo",
  EVENT_TIME_FROM: "timeFrom",
};
