import { ISocketPacket } from "../../types/socketPacket";

const convertFunction = JSON.parse;

export const parseSocketPacket = <T>(packet: string): ISocketPacket<T> | null => {
  try {
    return convertFunction(packet);
  } catch (e) {
    console.log(e);
    return null;
  }
};
