import { Sections } from "@monorepo/utils/src/types/cellsSections";

export const getPath = (name: string): string => {
  switch (name) {
    case Sections.EAD:
      return "/inventory/ead";
    case Sections.CASE:
      return "/inventory/case";
    case Sections.RECEIPT_FROM_OIK:
      return "/reports/received-documents";
    case Sections.FUND:
      return "/dictionaries/fund";
    case Sections.ARCHIVE:
      return "/dictionaries/archive";
    case Sections.OIK:
      return "/dictionaries/oik";
    case Sections.EVENT:
      return "/monitoring/event-log";
    case Sections.CONTAINER_LOG:
      return "/monitoring/tk_processing_log";
    case Sections.TK_LIST:
      return "/inventory/tk";
    case Sections.SYSTEM_NOTIFICATIONS:
      return "/administration/system-notifications";
    case Sections.USERS_LIST:
      return "/information-security/system-access/users";
    case Sections.RIGHTS_USERS_LIST:
      return "/information-security/system-access/roles";
    case Sections.AUDIT:
      return "/information-security/information-security-log";
    case Sections.AK:
    case "RECEIPT_AK":
      return "/inventory/ak";
    case Sections.FUNDS_REPORTS:
      return "/reports/funds-reports";
    case Sections.GENERATED_RECEIPTS:
      return "/reports/generated-reports";
    case Sections.INVENTORY:
      return "/inventory/case-notes-list";
    case Sections.ENDING_EAD_STORAGE_REPORTS:
      return "/reports/ead-storage-reports";
    case Sections.FILESTORAGE:
      return "/administration/filestorage";
    case Sections.BUG_REPORT:
      return "/administration/bug-reports";
    case Sections.SYNC_LOG_OIK:
      return "/monitoring/sync-log-oik/oikTab";
    case Sections.ACTS:
      return "/inventory/acts";
    case Sections.CASE_TRANSFER:
      return "/inventory/case-transfer-years";
    case Sections.TK_GENERATION:
      return "/administration/tk-generation";
    case Sections.DICTIONARY:
      return "";
    case Sections.DICTIONARY_ITEM:
      return "";
    case Sections.TASKS:
      return "";
    case Sections.MONITORING:
      return "/monitoring/monitoring";
    case "SHIPPING_CONTAINER":
      return "/monitoring/tk_processing_log";
    default:
      return "";
  }
};
