import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/eventLog/fields";

export const modalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Тип",
    className: "info-modal__element",
    value: fields.EVENT_TYPE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Код ошибки",
    className: "info-modal__element",
    value: fields.EVENT_ERROR_CODE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата",
    className: "info-modal__element",
    value: fields.EVENT_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Объект",
    className: "info-modal__element",
    value: fields.EVENT_OBJ,
    link: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь",
    className: "info-modal__element_title",
    value: fields.EVENT_USER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Источник",
    className: "info-modal__element",
    value: fields.EVENT_SOURCE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Категория",
    className: "info-modal__element",
    value: fields.EVENT_CATEGORY,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Описание",
    className: "info-modal__element_full-size",
    childrenClassName: "info-element-wrapped",
    value: fields.EVENT_DESCRIPTION,
  },
];
