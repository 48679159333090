





































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/monitoring/src/views/EventLogView/components/Filters.vue";
import SortPanelContainer from "@monorepo/monitoring/src/views/EventLogView/components/SortPanelContainer.vue";
import EventLogInfoModal from "@monorepo/monitoring/src/views/EventLogView/components/EventLogInfoModal.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { defineComponent, ref } from "@vue/composition-api";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import useSocket from "@monorepo/monitoring/src/views/EventLogView/composables/useSocket";
import useEventsSocketLogic from "@monorepo/monitoring/src/views/EventLogView/composables/useEventsSocketLogic";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { module as eventLogView } from "@monorepo/monitoring/src/store/modules/eventLogView";
import eventBus from "@monorepo/utils/src/eventBus";
import useGetTableList from "@monorepo/monitoring/src/views/EventLogView/composables/useGetTableList";
import useGetTableLibs from "@monorepo/monitoring/src/views/EventLogView/composables/useGetTableLibs";
import useSetStartFilters from "@monorepo/monitoring/src/views/EventLogView/composables/useSetStartFilters";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/eventLog/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/eventLog/viewTitle";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/monitoring/src/views/EventLogView/constants/breadcrumbs";
import { MONITORING_EVENT_BUS_EVENTS } from "@monorepo/utils/src/eventBus/events/monitoringEvents";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { convertApiItemToUi } from "@monorepo/monitoring/src/views/EventLogView/utils/convertDataItemToTableView";
import IdCell from "@monorepo/uikit/src/components/tableViews/IdCell.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/eventLog/fields";
import { IEvent } from "@monorepo/monitoring/src/views/EventLogView/types/Event";
import { getAuthorityByType } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/getAuthorityByType";
import { getPath } from "@monorepo/monitoring/src/views/EventLogView/constants/getPath";

const SECTION_NAME = "eventLogView";

export default defineComponent({
  name: "EventLogView",
  components: {
    TableSettings,
    ToggleTableCellsContainer,
    ExportButton,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    EventLogInfoModal,
    IdCell,
  },
  data() {
    return {
      breadcrumbs,
      viewUniqKey,
      viewTitle,
      tableHeaders,
      fields,
      convertApiItemToUi,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "isOpenFilters",
      "cells",
      "isTableLoading",
      "isSelectAll",
      "data",
      "openedId",
      "infiniteId",
      "selectedIds",
      "totalLength",
    ]),
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.EVENT_LOG_EXPORT);
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
    isAuthorityExist() {
      return (event: IEvent) => {
        const authorities = getAuthorityByType(event, "section");
        const authoritiesObject = this.user?.authorities.reduce((result: { [key: string]: boolean }, item: { authority: string }) => {
          result[item.authority || ""] = true;
          return result;
        }, {});
        return !!authorities.find((key: string) => authoritiesObject[key]);
      };
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "setIsOpenFilters"]),
    getEventListFinish() {
      eventBus.$emit(MONITORING_EVENT_BUS_EVENTS.REFRESH_EVENTS_FINISH);
    },
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
    clickItem(header: { value: string }, event: IEvent) {
      if (header.value === fields.EVENT_OBJ) {
        if (!this.isAuthorityExist(event)) {
          return;
        }
        this.$router.push({ path: getPath(event?.section || ""), query: { id: event[header.value as keyof IEvent], isOpenModal: "1" } });
      }
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          if (event.id) {
            this.$store.dispatch("eventLogView/addOpenedId", event.id);
            this.toggleModal(true);
          }
          break;
        default:
          break;
      }
    },
  },
  setup(props, { root }) {
    const socketPath = `${environmentVariables.WORKER_SERVICE_PATH}/ws`;
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const filtersLoaded = ref(false);

    useInitTableStoreModule(root, SECTION_NAME, eventLogView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);
    useSetStartFilters(root).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
    });
    useGetTableList(root);
    useGetTableLibs(root);
    useSocket(socketPath, root)
      .then(({ stompClient }) => {
        useEventsSocketLogic(stompClient, root);
      })
      .catch((e) => {
        console.error("Ошибка соединия с сокетом", e);
        showNotification("Ошибка соединения с сервером");
      });

    return {
      filtersLoaded,
      sectionName,
    };
  },
});
