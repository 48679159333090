import { fields } from "@monorepo/utils/src/variables/projectsData/eventLog/fields";

export const filtersElements = [
  {
    value: fields.EVENT_TYPE,
    title: "Тип",
  },
  {
    value: fields.EVENT_SOURCE,
    title: "Источник",
  },
  {
    value: fields.EVENT_DATE,
    title: "Период",
  },
  {
    value: fields.EVENT_USER,
    title: "Пользователь",
  },
  {
    value: fields.EVENT_CATEGORY,
    title: "Категория",
  },
  {
    value: fields.EVENT_DESCRIPTION,
    title: "Описание",
  },
  {
    value: fields.EVENT_OBJ,
    title: "Объект",
  },
];
