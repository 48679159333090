import { ComponentInstance } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { MONITORING_EVENT_BUS_EVENTS } from "@monorepo/utils/src/eventBus/events/monitoringEvents";

const useInitTableStoreModule = async (root: ComponentInstance): Promise<void> => {
  const query = root.$route.query;
  const store = root.$store;
  // await store.dispatch("eventLogView/getSavedFilters").catch(console.error);
  if (query.obj) {
    store.dispatch("eventLogView/addFilter", { obj: query.obj });
  }
  if (query.type) {
    store.dispatch("eventLogView/addFilter", { type: [query.type] });
  }

  eventBus.$once(MONITORING_EVENT_BUS_EVENTS.REFRESH_EVENTS_FINISH, () => {
    if (query.obj) {
      const id = store.state.eventLogView.data?.[0].id;
      if (id) {
        store.dispatch("eventLogView/addOpenedId", id);
      }
    }
  });
};

export default useInitTableStoreModule;
