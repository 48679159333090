import { get } from "lodash";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAuthorityByType = (item: Record<string, any>, key = "auditObjectType.code"): authorities[] => {
  switch (get(item ?? {}, key)) {
    case "TK":
    case "CONTAINER_WORKER":
    case "SHIPPING_CONTAINER":
      return [authorities.TK_DOCUMENTS_LIST, authorities.TK_DICTIONARIES_LIST, authorities.TK_OTHER_LIST];
    case "USER":
      return [authorities.USER_LIST];
    case "SCREENSHOT_BUG_REPORT":
      return [authorities.ERROR_MSG_LIST];
    case "ROLE":
      return [authorities.ROLE_LIST];
    case "AK":
      return [authorities.AK_LIST];
    case "OIK":
      return [authorities.OIK_LIST];
    case "FUND":
      return [authorities.FUND_LIST];
    case "EAD":
      return [authorities.EAD_LIST];
    case "UPDATE_ARCHIVE":
    case "ARCHIVE":
      return [authorities.ARCHIVE_LIST];
    default:
      return [];
  }
};
