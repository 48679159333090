import { FieldFilterTypes } from "@monorepo/monitoring/src/views/EventLogView/types/FieldFilterTypes";
import { isEmpty } from "lodash";
import { FilterTypes } from "@monorepo/monitoring/src/views/EventLogView/types/FilterTypes";
import { IEvent } from "@monorepo/monitoring/src/views/EventLogView/types/Event";
import { fieldFiltersValue } from "@monorepo/utils/src/store/types/tableFiltersObject";

export const checkFilters = (filters: Record<string, fieldFiltersValue>) => {
  return (item: IEvent): boolean => {
    const filtersArr = Object.entries(filters).filter(([, value]) => !!value);
    if (!filtersArr.length) {
      return true;
    }

    return Object.entries(item)
      .filter(([, value]) => !isEmpty(value))
      .every(([key, value]) => {
        const type: FilterTypes = FieldFilterTypes[key as keyof typeof FieldFilterTypes];
        const elementValue = value;

        if (type === FilterTypes.TEXT && filters[key]) {
          return elementValue.includes(filters[key]);
        }

        if (type === FilterTypes.MULTI_SELECT && (filters[key] as string[])?.length) {
          return !!(filters[key] as string[]).find((valueItem: string) => valueItem === elementValue);
        }

        if (type === FilterTypes.DATE && filters.dateFrom) {
          return elementValue >= filters.dateFrom;
        }

        if (type === FilterTypes.TIME && filters.timeFrom) {
          return elementValue >= filters.timeFrom;
        }

        if (type === FilterTypes.DATE && filters.dateTo) {
          return elementValue <= filters.dateTo;
        }

        if (type === FilterTypes.TIME && filters.timeTo) {
          return elementValue <= filters.timeTo;
        }

        return true;
      });
  };
};
