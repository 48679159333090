












































































































import { defineComponent, ref } from "@vue/composition-api";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import TimePicker from "@monorepo/uikit/src/components/tableViews/TimePicker.vue";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/eventLog/filtersElements";
import { currentFilters as setCurrentFilters } from "../utils/defaultCurrentFilters";
import { getQuery } from "@monorepo/monitoring/src/views/EventLogView/constants/getQuery";
import { mapActions, mapGetters } from "vuex";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/eventLog/viewTitle";
import { fields } from "@monorepo/utils/src/variables/projectsData/eventLog/fields";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import ToggleFilters from "@monorepo/uikit/src/components/tableViews/ToggleFilters.vue";
import ClearFilters from "@monorepo/uikit/src/components/tableViews/ClearFilters.vue";
import { debounce } from "lodash";
import useTemplateFilters from "@monorepo/utils/src/composables/useTemplateFilters";
import useFilter from "@monorepo/utils/src/composables/useFilter";

export default defineComponent({
  components: {
    SelectFilter,
    TextFilter,
    DatePicker,
    TimePicker,
    FindButton,
    ToggleFilters,
    ClearFilters,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sectionName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      filtersElements,
      viewUniqKey,
      fields,
    };
  },
  computed: {
    ...mapGetters("eventLogView", [
      "isTableLoading",
      "usersLib",
      "sourcesLib",
      "categoriesLib",
      "typesLib",
      "fieldFilters",
      "searchTemplates",
      "isLoadingToggleFilters",
    ]),
    dateFrom: {
      get(): string {
        return this.currentFilters[fields.EVENT_DATE_FROM] as string;
      },
      set(value: string): void {
        if (this.currentFilters[fields.EVENT_DATE_TO]) {
          this.currentFilters[fields.EVENT_DATE_TO] = "";
          this.currentFilters[fields.EVENT_TIME_TO] = "";
        }
        this.currentFilters[fields.EVENT_DATE_FROM] = value;
      },
    },
  },
  methods: {
    ...mapActions("eventLogView", ["clearFilters", "addFilter", "saveSearchTemplates"]),
    setCurrentFilters,
    clearFiltersComponentCb: debounce(
      function (this: { clearFiltersCb: () => void }) {
        this.clearFiltersCb();
        getQuery();
      },
      1000,
      { leading: true, trailing: false }
    ),
    refreshComponentCb: debounce(
      function (this: { refresh: () => void }) {
        this.refresh();
        getQuery();
      },
      1000,
      { leading: true, trailing: false }
    ),
  },
  setup(props, context) {
    const { root } = context;
    const currentFilters = ref((setCurrentFilters as () => Record<string, unknown>)());
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const autocompleteItemsResultLists = ref({} as { [key: string]: Record<string, unknown>[] });
    const isLoadingAutocomplete = ref(false);
    useTemplateFilters(root, props.sectionName, currentFilters);
    const { filter, hiddenRowsStyleObj, openHiddenFilterOnStart, toggleOpenFilter, clearFiltersCb, refresh, updateFiltersDivHeight } = useFilter(
      context,
      props,
      props.sectionName,
      {},
      currentFilters,
      setCurrentFilters,
      filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle
    );

    return {
      filter,
      hiddenRowsStyleObj,
      openHiddenFilterOnStart,
      toggleOpenFilter,
      clearFiltersCb,
      refresh,
      updateFiltersDivHeight,
      currentFilters,
      notHiddenKeys,
      isShowToggle,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
    };
  },
});
