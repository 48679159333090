import { IEvent } from "@monorepo/monitoring/src/views/EventLogView/types/Event";
import { convertTypeToText } from "@monorepo/monitoring/src/views/EventLogView/utils/convertTypeToText";
import moment from "moment";

const truncate = (input: string, length: number): string => (input.length > length ? `${input.substring(0, length)}...` : input);

export interface IResultEvent extends IEvent {
  type: string;
  date: string;
  description: string;
}
export const convertDataItemToTableView = (item: IEvent, isFullDescription = false): IResultEvent => {
  return {
    ...item,
    type: convertTypeToText(item.type),
    date: moment(item.date).format("YYYY-MM-DD HH:mm"),
    description: isFullDescription ? item.description : truncate(item.description || "", 30),
  };
};

export const convertApiItemToUi = (item: IEvent): IResultEvent => {
  return {
    ...convertDataItemToTableView(item, true),
  };
};
