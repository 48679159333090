import { EVENT_TYPE } from "@monorepo/monitoring/src/views/EventLogView/types/EventTypes";

export const convertTypeToText = (type: string): string => {
  switch (type) {
    case EVENT_TYPE.ACTION:
      return "Действие пользователя";
    case EVENT_TYPE.ERROR:
      return "Ошибка";
    case EVENT_TYPE.MESSAGE:
      return "Событие";
    default:
      return "";
  }
};
