import { FilterTypes } from "@monorepo/monitoring/src/views/EventLogView/types/FilterTypes";

export const FieldFilterTypes = {
  type: FilterTypes.MULTI_SELECT,
  date: FilterTypes.DATE,
  time: FilterTypes.TIME,
  source: FilterTypes.MULTI_SELECT,
  category: FilterTypes.MULTI_SELECT,
  description: FilterTypes.TEXT,
  obj: FilterTypes.TEXT,
  user: FilterTypes.MULTI_SELECT,
};
